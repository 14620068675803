@import "~bootstrap/scss/bootstrap.scss";
@import "~react-datepicker/dist/react-datepicker.css";
@import "variables";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~rc-time-picker/assets/index.css";
@import "themes";
@import "home";
@import "esign";
@import "timesheet";
@import "holidays";
@import "support";
@import "payroll";
@import "print";
@import "dropzone";
@import "google";

.navbar {
  padding-left: 0;
}

.tax-calculator {
  min-height: 400px;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", Arial, sans-serif;
  background-color: #dceef9;
}

.spinner-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.required:after {
  content: " *";
  color: red;
}

td.z-index-3 {
  z-index: 3 !important;
}

.btn-xero {
  background-color: #0c7ba0;
  padding: 0;
  .btn-xero-text {
    margin-top: 8px;
  }
}

.seperator {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #eee;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.seperator span {
  background: #fff;
  padding: 0 10px;
  color: #666;
}

.main-content {
  min-height: calc(100vh - 245px);
}

.main-content-secure {
  min-height: calc(100vh - 185px);
}

.menu-category {
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  opacity: 0.7;
  letter-spacing: 0.5px;
}

.secure-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
