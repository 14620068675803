.pdf-timesheet {
  width: 1000px;
  max-height: 400px;
}

.e-sign .visible {
  display: block;
}

.e-sign .hidden {
  display: none;
}
