body {
  font-family: "Open Sans", sans-serif;
  &.weight600 {
    font-weight: 600;
  }
}

#pdf-content {
  font-family: "Open Sans", sans-serif;
  &.weight600 {
    font-weight: 600;
  }
}

.content {
  min-height: 300px;
}

.theme-blue {
  background-color: #215d88;
  padding: 16px;
  color: #fff;
}

.theme-light-blue {
  background-color: #f4f9fd;
  padding: 16px;
}

.theme-dark {
  background-color: #212529;
  color: #fff !important;
}

.theme-dark-blue {
  padding: 16px;
  color: #fff;
  background-color: #08131a;
}

.theme-dark-blue a {
  color: #fff !important;
}

.theme-primary {
  background-color: #112f44;
  padding: 5px;
  color: #fff;
}

.h1Divider {
  border-bottom: 3px solid #fff;
  max-width: 50px;
}
.theme-primary h1 {
  color: #fff;
}

.theme-dark-blue a {
  color: #fff !important;
}

.theme-purple {
  background-color: #49140c;
  padding: 16px;
  color: #fff;
}

.theme-white {
  background-color: #fff;
  padding: 16px;
  color: #000;
}

.theme-grey {
  background-color: #e2e2e2;
  padding: 16px;
  color: #000;
  .card {
    background-color: inherit !important;
  }
}

.theme-brown {
  background-color: #bf824c;
  padding: 16px;
  color: #000;
}

.theme-footer {
  background-color: #112f44;
  padding: 16px;
  color: #fff;
}

.theme-footer a {
  color: #fff;
}

.theme-footer ul {
  list-style: none;
  padding-top: 20px;
}

.theme-footer li {
  line-height: 20px;
  margin-bottom: 20px;
}

html {
  position: relative;
  min-height: 100%;
}

input,
button,
a.btn {
  border-radius: 0 !important;
}

.navbar .nav-link {
  color: #fff !important;
}

.btn-sq-lg {
  width: 150px !important;
  height: 150px !important;
}

.btn-sq {
  width: 100px !important;
  height: 100px !important;
  font-size: 10px;
}

.btn-sq-sm {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px;
}

.btn-sq-xs {
  width: 25px !important;
  height: 25px !important;
  padding: 2px;
}

.footer img {
  max-width: 200px;
}

.rc-time-picker.disabled {
  border: 0;
}

.rc-time-picker.disabled input {
  color: #666;
  background-color: inherit;
  cursor: default;
  border: 0;
}

.line-total {
  color: #666;
  font-size: 12px;
}

.btn-acader {
  background-color: #b9ddf5;
  color: #000;
  &:hover {
    background-color: #91c6e9;
  }
  &:disabled {
    background-color: #e2edf5;
    color: #666;
  }
}
