.timesheet table {
  border: 1px solid #dfe1e3;
}

.timesheet .head {
  background-color: #2f2f2f;
  color: #fff;
  padding: 5px;
  text-align: center;
  font-weight: 600;
}

.timesheet .row {
  border-bottom: 1px solid #d9d9d9;
}

.timesheet .empty {
  padding: 5px;
  text-align: center;
  min-height: 50px;
}
.timesheet .active {
  background-color: #fff;
  padding: 5px;
  text-align: center;
  min-height: 50px;
}

.timesheet .hours.disabled {
  background-color: #fff;
  border: 0;
  text-align: center;
  font-weight: 700;
}

.success-message {
  transform: translate(0);
  opacity: 1;
}
.success-message.hidden {
  transition: opacity 2s linear;
  opacity: 0;
}

.timesheet-date {
  font-weight: 600;
  font-size: 10px;
  text-align: left;
}

.week-table thead {
  background-color: #235675;
  color: #fff;
}

@media (min-width: 768px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 100%;
    *width: 100%;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }
}

@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }
}

.template-col {
  background-color: #eee;
  padding: 10px;
}

.c-time {
  vertical-align: top;
}
