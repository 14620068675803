img {
  max-width: 200px;
}
ul {
  list-style-type: none;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
.holder {
  padding: 5px 50px 0px 50px;
}

.printTable th,
.printTable td {
  padding: 5px;
}
.printTable th {
  text-align: left;
}
/* Weekdays (Mon-Sun) */
.weekdays {
  margin: 0;
  padding: 10px 0;
  background-color: #000;
  color: #fff;
}

.time-badge {
  color: #fff !important;
}
.weekdays li {
  display: inline-block;
  width: 13.6%;
  color: #fff;
  text-align: center;
}

/* Days (1-31) */
.days {
  padding: 10px 0;
  background: #fff;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;

  margin-bottom: 20px;
}
hr {
  border-top: 1px solid #eee;
}
.logo {
  background-color: #112f44;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.approved {
  padding: 2px;
  background-color: #28a745;
  color: #fff;
  border-radius: 5px;
}

.draft {
  padding: 2px;
  background-color: #ffc107;
  color: #fff;
  border-radius: 5px;
}

.sent {
  padding: 2px;
  background-color: #17a2b8;
  color: #fff;
  border-radius: 5px;
}

.time {
  background-color: #000;
  color: #fff;
}

.time-data:nth-child(odd) {
  background-color: #eee;
}
.printDetails th,
.printDetails td {
  border: 1px solid #fff;
  border-collapse: collapse;
  padding: 8px;
}
.printDetails th {
  background-color: #eee;
}
