.banner {
  min-height: 70px;
}
.banner .divider {
  min-height: 50px;
  border-right: 1px solid #fff;
  width: 80px;
  float: left;
  margin-top: 10px;
}

.banner .fa-clock {
  font-size: 60px;
  margin-top: 5px;
}

.banner .text {
  font-weight: 700;
  font-size: 44px;
  float: left;
  margin-left: 20px;
}

.banner a {
  color: #fff !important;
}
.home .card {
  border: 0;
  box-shadow: none;
}

.login {
  background-color: #e1e1e1;
  max-width: 500px;
  margin-top: 32px;
  padding: 64px;
  border-radius: 5px;
  box-shadow: 4px 4px #5e5d5c;
}

.login .btn-link {
  color: #212529;
}

.login-divider {
  border-right: 1px solid #000;
  min-height: 300px;
  text-align: center;
  margin: 0 auto;
}

.banner img {
  max-width: 180px;
  margin-left: 15px;
  margin-top: 15px;
}

@media only screen and (max-width: 1000px) {
  .banner .divider {
    min-height: 70px;
    border-right: 1px solid #fff;
    width: 70px;
    float: left;
  }

  .banner .fa-clock {
    font-size: 60px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 780px) {
  .banner .divider {
    min-height: 60px;
    border-right: 1px solid #fff;
    width: 60px;
    float: left;
  }

  .banner img {
    max-width: 180px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .banner .fa-clock {
    font-size: 50px;
    margin-top: 5px;
  }
}

.services-divider-bottom {
  border-bottom: 2px solid #666;
}

.services-divider-top {
  color: #83cd29;
  margin-top: 40px;
}

.services-img {
  margin-top: 30px;
}

.services img {
  max-width: 100px;
  margin-top: 20px;
}

.products-bottom {
  border-bottom: 2px solid #eee;
}

.clients {
  img {
    padding: 10px;
  }
}

.mission,
#services,
#clients,
#aboutus,
#contactus {
  .fas,
  .fab {
    color: #112f44;
    text-align: center;
  }
  .section {
    font-weight: 600;
    text-transform: uppercase !important;
    font-size: 1.2em !important;
    letter-spacing: 2.5px !important;
    color: #969696;
    margin-bottom: 10px;
  }
  h2 {
    color: #212529;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }
  p.text {
    color: #777;
    font-size: 1.35em !important;
    line-height: 1.5;
    font-weight: 500;
  }
  p.sub-text {
    color: #777;
    font-size: 1.1em !important;
    line-height: 26px;
    margin: 0 0 20px;
  }
  ul {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    color: #777;

    .far {
      color: #112f44;
    }
    li {
      padding-top: 8px;

      margin-bottom: 0.25rem !important;
    }
  }
}

.divider.divider-small {
  margin: 22px auto;
  background: 0 0;
}
.divider {
  border: 0;
  height: 1px;
  margin: 44px auto;
  background: rgba(0, 0, 0, 0.06);
  text-align: center;
  position: relative;
  clear: both;
}

.divider.divider-small hr {
  width: 50px;
  height: 3px;
  background: #555;
}

#services {
  .cards {
    .col-md-4 {
      margin-bottom: 20px;
    }

    .card-title {
      font-weight: 700;
      font-size: 1.5;
      margin-bottom: 0.5rem !important;
      margin-top: 1.5rem !important;
    }
    .card-text {
      color: #777;
      line-height: 26px;
      margin: 0 0 20px;
      font-size: 1.1em !important;
    }
  }
}

#clients {
  .card {
    min-height: 300px;
    margin-top: 20px;
    .card-title {
      font-weight: 700;
      font-size: 1.1em;
      margin-bottom: 0.5rem !important;
      margin-top: 20px;
      color: #000;
    }
  }
}
